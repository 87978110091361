import axios from "axios";
import { API_URL } from "../config";

const http = axios.create({
  baseURL: API_URL,
});
http.defaults.headers.post["Content-Type"] = "application/json";
http.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem("accessToken");
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

http.interceptors.response.use(
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      // let data = response.data;
      // if (data && data.user && data.user.role) {
      //   if (
      //     !["admin", "superadmin", "editor"].includes(
      //       String(data.user.role).toLowerCase()
      //     )
      //   ) {
      //     localStorage.removeItem("accessToken");
      //     return Promise.reject({
      //       status: "error",
      //       message: "User doesn't have enough permission",
      //     });
      //   }
      // }
      return Promise.resolve(response.data);
    }
  },
  async (error) => {
    if (error && error.message === "Network Error") {
      window.location.href = "/500";
    }
    const { response, request } = error;
    if (response) {
      console.log("error response: ", response);
      if (
        response.status === 401 ||
        (response.status === 409 &&
          String(response.data.code).toLowerCase() === "tokenexpirederror")
      ) {
        localStorage.removeItem("accessToken");

        // window.location.href = "/login";
      }
      if (response.status >= 400 && response.status <= 500) {
        return Promise.reject(response);
      }
    } else if (request) {
      return null;
    }
    return Promise.reject(error);
  }
);

export default http;
