// USER PRFOFILE
export const SUPERADMIN_GET_PROFILE = "SUPERADMIN_GET_PROFILE";
export function superAdminGetProfile(payload) {
  return { type: SUPERADMIN_GET_PROFILE, payload };
}
// BUSINESS TYPE
export const SUPERADMIN_GET_BUSINESSTYPES = "SUPERADMIN_GET_BUSINESSTYPES";
export const SUPERADMIN_ADD_BUSINESSTYPE = "SUPERADMIN_ADD_BUSINESSTYPE";
export const SUPERADMIN_UPDATE_BUSINESSTYPE = "SUPERADMIN_UPDATE_BUSINESSTYPE";
export const SUPERADMIN_DELETE_BUSINESSTYPE = "SUPERADMIN_DELETE_BUSINESSTYPE";

export function superAdminGetBusinessTypes(payload) {
  return { type: SUPERADMIN_GET_BUSINESSTYPES, payload };
}
export function superAdminAddBusinessType(payload) {
  return { type: SUPERADMIN_ADD_BUSINESSTYPE, payload };
}
export function superAdminUpdateBusinessType(payload) {
  return { type: SUPERADMIN_UPDATE_BUSINESSTYPE, payload };
}
export function superAdminDeleteBusinessType(payload) {
  return { type: SUPERADMIN_DELETE_BUSINESSTYPE, payload };
}

//TESTIMONIALS
export const SUPERADMIN_GET_TESTIMONIALS = "SUPERADMIN_GET_TESTIMONIALS";
export const SUPERADMIN_ADD_TESTIMONIAL = "SUPERADMIN_ADD_TESTIMONIAL";
export const SUPERADMIN_UPDATE_TESTIMONIAL = "SUPERADMIN_UPDATE_TESTIMONIAL";
export const SUPERADMIN_DELETE_TESTIMONIAL = "SUPERADMIN_DELETE_TESTIMONIAL";

export function superAdminGetTestimonials(payload) {
  return { type: SUPERADMIN_GET_TESTIMONIALS, payload };
}
export function superAdminAddTestimonial(payload) {
  return {
    type: SUPERADMIN_ADD_TESTIMONIAL,
    payload,
  };
}
export function superAdminUpdateTestimonial(payload) {
  return {
    type: SUPERADMIN_UPDATE_TESTIMONIAL,
    payload,
  };
}
export function superAdminDeleteTestimonial(payload) {
  return {
    type: SUPERADMIN_DELETE_TESTIMONIAL,
    payload,
  };
}

//FESTIVAL OFFERS
export const SUPERADMIN_GET_OFFERS = "SUPERADMIN_GET_OFFERS";
export const SUPERADMIN_ADD_OFFER = "SUPERADMIN_ADD_OFFER";
export const SUPERADMIN_UPDATE_OFFER = "SUPERADMIN_UPDATE_OFFER";
export const SUPERADMIN_DELETE_OFFER = "SUPERADMIN_DELETE_OFFER";

export function superAdminGetOffers(payload) {
  return { type: SUPERADMIN_GET_OFFERS, payload };
}
export function superAdminAddOffer(payload) {
  return {
    type: SUPERADMIN_ADD_OFFER,
    payload,
  };
}
export function superAdminUpdateOffer(payload) {
  return {
    type: SUPERADMIN_UPDATE_OFFER,
    payload,
  };
}
export function superAdminDeleteOffer(payload) {
  return {
    type: SUPERADMIN_DELETE_OFFER,
    payload,
  };
}

// REVIEWS
export const SUPERADMIN_GET_REVIEWS = "SUPERADMIN_GET_REVIEWS";
export const SUPERADMIN_ADD_REVIEW = "SUPERADMIN_ADD_REVIEW";
export const SUPERADMIN_UPDATE_REVIEW = "SUPERADMIN_UPDATE_REVIEW";
export const SUPERADMIN_DELETE_REVIEW = "SUPERADMIN_DELETE_REVIEW";

export function superAdminGetReviews(payload) {
  return { type: SUPERADMIN_GET_REVIEWS, payload };
}
export function superAdminAddReview(payload) {
  return {
    type: SUPERADMIN_ADD_REVIEW,
    payload,
  };
}
export function superAdminUpdateReview(payload) {
  return {
    type: SUPERADMIN_UPDATE_REVIEW,
    payload,
  };
}
export function superAdminDeleteReview(payload) {
  return {
    type: SUPERADMIN_DELETE_REVIEW,
    payload,
  };
}

// BRANCH
export const SUPERADMIN_GET_BRANCHES = "SUPERADMIN_GET_BRANCHES";
export const SUPERADMIN_ADD_BRANCH = "SUPERADMIN_ADD_BRANCH";
export const SUPERADMIN_UPDATE_BRANCH = "SUPERADMIN_UPDATE_BRANCH";
export const SUPERADMIN_DELETE_BRANCH = "SUPERADMIN_DELETE_BRANCH";

export function superAdminGetBranches(payload) {
  return { type: SUPERADMIN_GET_BRANCHES, payload };
}
export function superAdminAddBranch(payload) {
  return { type: SUPERADMIN_ADD_BRANCH, payload };
}
export function superAdminUpdateBranch(payload) {
  return { type: SUPERADMIN_UPDATE_BRANCH, payload };
}
export function superAdminDeleteBranch(payload) {
  return { type: SUPERADMIN_DELETE_BRANCH, payload };
}
