// --- SLIDER ---
export const COMMON_GET_SLIDERS = "COMMON_GET_SLIDERS";
export const COMMON_ADD_SLIDER = "COMMON_ADD_SLIDER";
export const COMMON_UPDATE_SLIDER = "COMMON_UPDATE_SLIDER";
export const COMMON_DELETE_SLIDER = "COMMON_DELETE_SLIDER";

export function commonGetSliders(payload) {
  return { type: COMMON_GET_SLIDERS, payload };
}
export function commonAddSlider(payload) {
  return { type: COMMON_ADD_SLIDER, payload };
}
export function commonUpdateSlider(payload) {
  return { type: COMMON_UPDATE_SLIDER, payload };
}
export function commonDeleteSlider(payload) {
  return { type: COMMON_DELETE_SLIDER, payload };
}

// --- CATEGORY ---
export const COMMON_GET_CATEGORIES = "COMMON_GET_CATEGORIES";
export const COMMON_ADD_CATEGORY = "COMMON_ADD_CATEGORY";
export const COMMON_UPDATE_CATEGORY = "COMMON_UPDATE_CATEGORY";
export const COMMON_DELETE_CATEGORY = "COMMON_DELETE_CATEGORY";

export function commonGetCategories(payload) {
  return { type: COMMON_GET_CATEGORIES, payload };
}
export function commonAddCategory(payload) {
  return { type: COMMON_ADD_CATEGORY, payload };
}
export function commonUpdateCategory(payload) {
  return { type: COMMON_UPDATE_CATEGORY, payload };
}
export function commonDeleteCategory(payload) {
  return { type: COMMON_DELETE_CATEGORY, payload };
}

// --- SUB-CATEGORY ---
export const COMMON_GET_SUB_CATEGORIES = "COMMON_GET_SUB_CATEGORIES";
export const COMMON_ADD_SUBCATEGORY = "COMMON_ADD_SUBCATEGORY";
export const COMMON_UPDATE_SUBCATEGORY = "COMMON_UPDATE_SUBCATEGORY";
export const COMMON_DELETE_SUBCATEGORY = "COMMON_DELETE_SUBCATEGORY";

export function commonGetSubCategories(payload) {
  return { type: COMMON_GET_SUB_CATEGORIES, payload };
}
export function commonAddSubCategory(payload) {
  return { type: COMMON_ADD_SUBCATEGORY, payload };
}
export function commonUpdateSubCategory(payload) {
  return { type: COMMON_UPDATE_SUBCATEGORY, payload };
}
export function commonDeleteSubCategory(payload) {
  return { type: COMMON_DELETE_SUBCATEGORY, payload };
}

// --- BRAND ---
export const COMMON_GET_BRANDS = "COMMON_GET_BRANDS";
export const COMMON_ADD_BRAND = "COMMON_ADD_BRAND";
export const COMMON_UPDATE_BRAND = "COMMON_UPDATE_BRAND";
export const COMMON_DELETE_BRAND = "COMMON_DELETE_BRAND";

export function commonGetBrands(payload) {
  return { type: COMMON_GET_BRANDS, payload };
}
export function commonAddBrand(payload) {
  return { type: COMMON_ADD_BRAND, payload };
}
export function commonUpdateBrand(payload) {
  return { type: COMMON_UPDATE_BRAND, payload };
}
export function commonDeleteBrand(payload) {
  return { type: COMMON_DELETE_BRAND, payload };
}

// --- PRODUCT ---
export const COMMON_GET_PRODUCTS = "COMMON_GET_PRODUCTS";
export const COMMON_ADD_PRODUCT = "COMMON_ADD_PRODUCT";
export const COMMON_UPDATE_PRODUCT = "COMMON_UPDATE_PRODUCT";
export const COMMON_DELETE_PRODUCT = "COMMON_DELETE_PRODUCT";

export function commonGetProducts(payload) {
  return { type: COMMON_GET_PRODUCTS, payload };
}
export function commonAddProduct(payload) {
  return { type: COMMON_ADD_PRODUCT, payload };
}
export function commonUpdateProduct(payload) {
  return { type: COMMON_UPDATE_PRODUCT, payload };
}
export function commonDeleteProduct(payload) {
  return { type: COMMON_DELETE_PRODUCT, payload };
}

// --- FEATURED ---
export const COMMON_GET_FEATUREDS = "COMMON_GET_FEATUREDS";
export const COMMON_ADD_FEATURED = "COMMON_ADD_FEATURED";
export const COMMON_UPDATE_FEATURED = "COMMON_UPDATE_FEATURED";
export const COMMON_DELETE_FEATURED = "COMMON_DELETE_FEATURED";

export function commonGetFeatureds(payload) {
  return { type: COMMON_GET_FEATUREDS, payload };
}
export function commonAddFeatured(payload) {
  return { type: COMMON_ADD_FEATURED, payload };
}
export function commonUpdateFeatured(payload) {
  return { type: COMMON_UPDATE_FEATURED, payload };
}
export function commonDeleteFeatured(payload) {
  return { type: COMMON_DELETE_FEATURED, payload };
}

// --- PROMOTIONS ---
export const COMMON_GET_PROMOTIONS = "COMMON_GET_PROMOTIONS";
export const COMMON_ADD_PROMOTION = "COMMON_ADD_PROMOTION";
export const COMMON_UPDATE_PROMOTION = "COMMON_UPDATE_PROMOTION";
export const COMMON_DELETE_PROMOTION = "COMMON_DELETE_PROMOTION";

export function commonGetPromotions(payload) {
  return { type: COMMON_GET_PROMOTIONS, payload };
}
export function commonAddPromotion(payload) {
  return { type: COMMON_ADD_PROMOTION, payload };
}
export function commonUpdatePromotion(payload) {
  return { type: COMMON_UPDATE_PROMOTION, payload };
}
export function commonDeletePromotion(payload) {
  return { type: COMMON_DELETE_PROMOTION, payload };
}

// --- ORDER ---
export const COMMON_GET_ORDERS = "COMMON_GET_ORDERS";
export const COMMON_UPDATE_ORDER = "COMMON_UPDATE_ORDER";
export const COMMON_UPDATE_ORDERS = "COMMON_UPDATE_ORDERS";
export const COMMON_DELETE_ORDER = "COMMON_DELETE_ORDER";

export function commonGetOrders(payload) {
  return { type: COMMON_GET_ORDERS, payload };
}
export function commonUpdateOrders(payload) {
  return { type: COMMON_UPDATE_ORDERS, payload };
}
export function commonUpdateOrder(payload) {
  return { type: COMMON_UPDATE_ORDER, payload };
}
export function commonDeleteOrder(payload) {
  return { type: COMMON_DELETE_ORDER, payload };
}

// --- INVENTORY ---
export const COMMON_GET_INVENTORIES = "COMMON_GET_INVENTORIES";
export const COMMON_UPDATE_INVENTORY = "COMMON_UPDATE_INVENTORY";
export const COMMON_GET_INVENTORY_ITEM = "COMMON_GET_INVENTORY_ITEM";

export function commonGetInventories(payload) {
  return { type: COMMON_GET_INVENTORIES, payload };
}
export function commonUpdateInventory(payload) {
  return { type: COMMON_UPDATE_INVENTORY, payload };
}
export function commonGetInventoryItem(payload) {
  return { type: COMMON_GET_INVENTORY_ITEM, payload };
}

// --- BLOG ---
export const COMMON_GET_BLOGS = "COMMON_GET_BLOGS";
export const COMMON_ADD_BLOG = "COMMON_ADD_BLOG";
export const COMMON_UPDATE_BLOG = "COMMON_UPDATE_BLOG";
export const COMMON_DELETE_BLOG = "COMMON_DELETE_BLOG";

export function commonGetBlogs(payload) {
  return { type: COMMON_GET_BLOGS, payload };
}
export function commonAddBlog(payload) {
  return { type: COMMON_ADD_BLOG, payload };
}
export function commonUpdateBlog(payload) {
  return { type: COMMON_UPDATE_BLOG, payload };
}
export function commonDeleteBlog(payload) {
  return { type: COMMON_DELETE_BLOG, payload };
}

// --- CONTACT ---
export const COMMON_GET_CONTACT_FORMS = "COMMON_GET_CONTACT_FORMS";
export const COMMON_DELETE_CONTACT_FORM = "COMMON_DELETE_CONTACT_FORM";

export function commonGetContactForms(payload) {
  return { type: COMMON_GET_CONTACT_FORMS, payload };
}
export function commonDeleteContactForm(payload) {
  return { type: COMMON_DELETE_CONTACT_FORM, payload };
}

// --- DELIVERY SCHEDULE ---
export const COMMON_GET_DELIVERY_SCHEDULES = "COMMON_GET_DELIVERY_SCHEDULES";
export const COMMON_ADD_DELIVERY_SCHEDULE = "COMMON_ADD_DELIVERY_SCHEDULE";
export const COMMON_UPDATE_DELIVERY_SCHEDULE =
  "COMMON_UPDATE_DELIVERY_SCHEDULE";
export const COMMON_DELETE_DELIVERY_SCHEDULE =
  "COMMON_DELETE_DELIVERY_SCHEDULE";

export function commonGetDeliverySchedules(payload) {
  return { type: COMMON_GET_DELIVERY_SCHEDULES, payload };
}
export function commonAddDeliverySchedule(payload) {
  return { type: COMMON_ADD_DELIVERY_SCHEDULE, payload };
}
export function commonUpdateDeliverySchedule(payload) {
  return { type: COMMON_UPDATE_DELIVERY_SCHEDULE, payload };
}
export function commonDeleteDeliverySchedule(payload) {
  return { type: COMMON_DELETE_DELIVERY_SCHEDULE, payload };
}

// --- TIME SLOT ---
export const COMMON_GET_TIMESLOTS = "COMMON_GET_TIMESLOTS";
export const COMMON_ADD_TIMESLOT = "COMMON_ADD_TIMESLOT";
export const COMMON_UPDATE_TIMESLOT = "COMMON_UPDATE_TIMESLOT";
export const COMMON_DELETE_TIMESLOT = "COMMON_DELETE_TIMESLOT";

export function commonGetTimeSlots(payload) {
  return { type: COMMON_GET_TIMESLOTS, payload };
}
export function commonAddTimeSlot(payload) {
  return { type: COMMON_ADD_TIMESLOT, payload };
}
export function commonUpdateTimeSlot(payload) {
  return { type: COMMON_UPDATE_TIMESLOT, payload };
}
export function commonDeleteTimeSlot(payload) {
  return { type: COMMON_DELETE_TIMESLOT, payload };
}

//paginations
export const COMMON_SET_PAGES = "SET_PAGES";
export const COMMON_SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export function setPagesAction(pages) {
  return {
    type: COMMON_SET_PAGES,
    payload: pages,
  };
}

export function setCurrentPageAction(currentPage) {
  return {
    type: COMMON_SET_CURRENT_PAGE,
    payload: currentPage,
  };
}
