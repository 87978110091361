import { io } from "socket.io-client";
import { API_URL } from "src/config";


// socket instance...
export function socketInstance() {
    const token = localStorage.getItem("accessToken");
    const socket = io(`${API_URL}`, {
        reconnectionDelayMax: 10000,
        auth: {
            auth_token: `${token}`
        }
    });
    return socket;
}
