export const APP_ENV = process.env.NODE_ENV || "development";
export const API_URL =
  process.env.REACT_APP_API_URL || "https://dev-api.citi977.com.np";
export const UPLOAD_URL =
  process.env.REACT_APP_UPLOAD_URL || "https://dev-api.citi977.com.np/api/v1";

//firebase

export const apiKey =
  process.env.REACT_APP_APIKEY || "AIzaSyDQMVIWot9oOOny7ehsroHkUQDPAcCWGwE";
export const authDomain =
  process.env.REACT_APP_AUTHDOMAIN || "pwa-react-f7ba8.firebaseapp.com";
export const projectId = process.env.REACT_APP_PROJECTID || "pwa-react-f7ba8";
export const storageBucket =
  process.env.REACT_APP_STORAGEBUCKET || "pwa-react-f7ba8.appspot.com";
export const messagingSenderId =
  process.env.REACT_APP_MESSAGINGSENDERID || "1098899806897";
export const appId =
  process.env.REACT_APP_APPID || "1:1098899806897:web:47fbe6be806868f04a6090";
export const vapidKey =
  process.env.REACT_APP_VAPIDKEY ||
  "BOE1TguzUzKSbfwLx-IZ6O3gNs3KRtOJ5DYc-_lwSg1g1uDlAi5ltGifMmSRoOM7Xc4DaOtje-DScXrLKXamVjY";
