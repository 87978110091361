import { NAV_SET } from "../actions/navActions";

const initialState = {
  sidebarShow: "responsive",
};

export default function navReducer(state = initialState, { type, payload }) {
  switch (type) {
    case NAV_SET:
      return { ...state, sidebarShow: payload.sidebarShow };
    default:
      return state;
  }
}
