import { combineReducers } from "redux";

import navReducer from "./navReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";
import superAdminReducer from "./superAdminReducer";
import commonReducer from "./commonReducer";
import notificationReducer from "./notificationReducer";

const appReducer = combineReducers({
  user: userReducer,
  nav: navReducer,
  admin: adminReducer,
  notification: notificationReducer,
  commonStore: commonReducer,
  superAdmin: superAdminReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
