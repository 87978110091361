export const isAdmin = (user) => {
  if (user && user.role) {
    if (user.role === "admin") return true;
  }
  return false;
};
export const isSuperAdmin = (user) => {
  if (user && user.role) {
    if (user.role === "superadmin") return true;
  }
  return false;
};

// get user role...
export const getUserRole = (userRole = localStorage.getItem("userRole")) => {
  if (userRole) return String(userRole).toLowerCase();
  return localStorage.getItem("userRole")?.toLowerCase();
};

// admin and superadmin role
export const getAdminRole = (userrole = getUserRole()) => {
  if (userrole === "admin" || userrole === "superadmin") return "admin";
  else return userrole;
};

export default {
  isAdmin,
  isSuperAdmin,
};
