import { GET_PROFILE, LOGIN_USER, USER_CHAT_HISTORY, CHAT_DISPLAY_SETTING, SELECTED_USER_TO_CHAT, USER_GET_USERS, USER_ADD_USER, USER_UPDATE_USER, USER_DELETE_USER, USER_GET_UNVERIFIED_DELIVEY_USERS, USER_VERIFY_DELIVERY_USER } from "../actions/userActions";

const initialState = {
  profile: {
    name: "",
    avatar: "avatars/user.png",
  },
  userChatHistory: [],
  chatDisplaySetting: {
    isChatBoxVisible: true,
    isUserBoxVisible: true,
  },
  selectedUserToChat: {},
  userList: [],
  unverifiedDeliveryUsers: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    // USER LOGIN
    case LOGIN_USER:
      return { ...state, profile: { ...state.profile, ...action.payload } };

    // USER PROFILE
    case GET_PROFILE:
      return { ...state, profile: { ...state.profile, ...action.payload } };


    // AVAILABLE USER LISTS
    case USER_GET_USERS:
      return { ...state, userList: action.payload };
    case USER_ADD_USER: {
      let users = [...state.userList];
      users.unshift(action.payload);
      return { ...state, userList: users };
    }
    case USER_UPDATE_USER: {
      let users = [...state.userList];
      const index = users.findIndex((cat) => cat._id === action.payload._id);
      users[index] = action.payload;
      return { ...state, userList: users };
    }
    case USER_DELETE_USER: {
      let users = [...state.userList]?.filter(val=>val._id!==action.payload);
      // const index = users.findIndex((cat) => cat._id === action.payload);
      // users.splice(index, 1);
      return { ...state, userList: users };
    }

    // USER CHAT
    case USER_CHAT_HISTORY:
      {
        if (action.payload.isReplace) {
          return {
            ...state,
            userChatHistory: [...action.payload.data],
          };
        }
        const data = state.userChatHistory;
        data.push(action.payload.data)
        return {
          ...state,
          userChatHistory: [...data],
        };
      }
    case CHAT_DISPLAY_SETTING:
      return {
        ...state,
        chatDisplaySetting: { ...action.payload },
      };
    case SELECTED_USER_TO_CHAT:
      return {
        ...state,
        selectedUserToChat: { ...action.payload },
      };


    // HANDLE UNVERIFIED DELIVERY USERS
    case USER_GET_UNVERIFIED_DELIVEY_USERS: {
      return {
        ...state,
        unverifiedDeliveryUsers: action.payload,
      }
    }
    case USER_VERIFY_DELIVERY_USER: {
      const data = state.unverifiedDeliveryUsers;
      const index = data.findIndex((val) => val === action.payload)
      data.splice(index, 1)
      return {
        ...state,
        unverifiedDeliveryUsers: data,
      }
    }



    default:
      return state;
  }
}
