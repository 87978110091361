// LOGIN USER
export const LOGIN_USER = "LOGIN_USER";
export function loginUser(payload) { return { type: LOGIN_USER, payload } }


// LOGOUT USER
export const LOGOUT_USER = "LOGOUT_USER";
export function logoutUser() { return { type: LOGOUT_USER } }


// GET USER PROFILE
export const GET_PROFILE = "GET_PROFILE";
export function getProfile(payload) { return { type: GET_PROFILE, payload } }


// USER CHAT HISTORY
export const USER_CHAT_HISTORY = "USER_CHAT_HISTORY";
export function setUserChatHisory(payload) { return { type: USER_CHAT_HISTORY, payload } }


// USER CHAT SETTINGS
export const CHAT_DISPLAY_SETTING = "CHAT_DISPLAY_SETTING";
export const SELECTED_USER_TO_CHAT = "SELECTED_USER_TO_CHAT";

export function setChatDisplaySetting(payload) { return { type: CHAT_DISPLAY_SETTING, payload } }
export function setSelectedUserToChat(payload) { return { type: SELECTED_USER_TO_CHAT, payload } }



// USER LISTS
export const USER_GET_USERS = "USER_GET_USERS";
export const USER_ADD_USER = "USER_ADD_USER";
export const USER_UPDATE_USER = "USER_UPDATE_USER";
export const USER_DELETE_USER = "USER_DELETE_USER";

export function userGetUsers(payload) { return { type: USER_GET_USERS, payload } }
export function userAddUser(payload) { return { type: USER_ADD_USER, payload } }
export function userUpdateUser(payload) { return { type: USER_UPDATE_USER, payload } }
export function userDeleteUser(payload) { return { type: USER_DELETE_USER, payload } }



// UNVERIFIED DELIBERY USER LISTS
export const USER_GET_UNVERIFIED_DELIVEY_USERS = "USER_GET_UNVERIFIED_DELIVEY_USERS";
export const USER_VERIFY_DELIVERY_USER = "USER_VERIFY_DELIVERY_USER";


export function userGetUnverifiedDeliveryUsers(payload) { return { type: USER_GET_UNVERIFIED_DELIVEY_USERS, payload } }
export function userVerifyDeliveryUser(payload) { return { type: USER_ADD_USER, payload } }
