// USER NOTIFICATIONS....
export const USER_GET_NOFIFICATIONS = "USER_GET_NOFIFICATIONS";
export const USER_ADD_NOTIFICATION = "USER_ADD_NOTIFICATION";
export const USER_BROADCAST_NOTIFICATION = "USER_BROADCAST_NOTIFICATION";
export const USER_ADD_PAGINATE_NOTIFICATION = "USER_ADD_PAGINATE_NOTIFICATION";
export const USER_NOFIFICATION_SEEN = "USER_NOFIFICATION_SEEN";
export const NOTIFICATION_CLICKED = "NOTIFICATION_CLICKED";
export const NOTIFICATION_RECEIVED = "NOTIFICATION_RECEIVED";

export function userGetNotifications(payload) {
  return { type: USER_GET_NOFIFICATIONS, payload };
}
export function userBroadcastNotification(payload) {
  return { type: USER_BROADCAST_NOTIFICATION, payload };
}
export function userAddNotification(payload) {
  return { type: USER_ADD_NOTIFICATION, payload };
}
export function userAddPaginateNotification(payload) {
  return { type: USER_ADD_PAGINATE_NOTIFICATION, payload };
}
export function userNotificationSeen(payload) {
  return { type: USER_NOFIFICATION_SEEN, payload };
}
export function notificationClicked(payload) {
  return { type: NOTIFICATION_CLICKED, payload };
}
export function notificationReceived(payload) {
  return { type: NOTIFICATION_RECEIVED, payload };
}

// MANAGE INFO_NOTIFICATIONS...
export const SET_INFO_MESSAGE = "SET_INFO_MESSAGE";
export const REMOVE_INFO_MESSAGE = "REMOVE_INFO_MESSAGE";

export const DEFAULT_SETMESSAGE_PARAMS = {
  delay: 5000,
  type: "success",
  color: "light",
  msg: "",
  show: true,
  placement: "top-right",
};

export function setInfoMessage(payload) {
  const time = new Date().getTime() + payload.delay;

  payload["delay"] = time + payload.delay;

  return {
    type: SET_INFO_MESSAGE,
    payload,
  };
}
export function removeInfoMessage(payload) {
  return { type: REMOVE_INFO_MESSAGE, payload };
}
