/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { onMessageListener } from "./firebase";
import { useDispatch } from "react-redux";
import sound from "../sound.mp3";
import { socketInstance } from "src/services/socket";

import { notificationApiService } from "src/services/commonService";
import { userApiService } from "./userService";
import { commonUpdateOrders } from "src/store/actions/commonActions";

const Notification = () => {
  // const userProfile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();
  const currentVendor = localStorage.getItem("restaurant");
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });

  useEffect(() => {
    notificationApiService().getNotifications(dispatch);
    userApiService().profile(dispatch);
  }, [notification]);

  useEffect(() => {
    if (currentVendor) {
      socketInstance().on(`notification:order:vendor:create`, (payload) => {
        const { title, body, data, restaurant: vendorId } = payload;
        if (currentVendor.toString() === vendorId.toString()) {
          setNotification({ title: title, body: body });
          if (data) {
            dispatch(commonUpdateOrders(payload.data));
          }
          notify();
        }
      });
    }

    if (!currentVendor) {
      socketInstance().on(`notification:order:create`, (payload) => {
        const { title, body, data } = payload;
        setNotification({ title: title, body: body });
        if (data) {
          dispatch(commonUpdateOrders(payload.data));
        }
        notify();
      });
    }
  }, [socketInstance]);

  function ToastDisplay(props) {
    const audioRef = useRef(null);

    useEffect(() => {
      if (audioRef.current) {
        audioRef.current.play();
      }

      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      };
    }, []);
    const handleClick = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      if (props.onClick) {
        props.onClick();
      }
    };
    return (
      <div onClick={handleClick}>
        <audio src={sound} ref={audioRef} loop />
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }
  const handleDismiss = () => {
    toast.dismiss();
  };
  const notify = () => {
    // toast.custom((t) => (
    //   <div
    //     style={{ background: "white", padding: "20px", borderRadius: "10px" }}
    //   >
    //     <div onClick={() => toast.remove(t.id)} aria-hidden>
    //       Clear icon
    //     </div>
    //     <p>
    //       <b>{notification?.title}</b>
    //     </p>
    //     <p>{notification?.body}</p>
    //   </div>
    // ));
    toast(<ToastDisplay onClick={handleDismiss} />, { duration: Infinity });
  };

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Toaster
      position="right-top"
      toastOptions={{
        duration: Infinity,
      }}
    />
  );
};

export default Notification;
