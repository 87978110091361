import http from "./api";

import {
  setUserChatHisory,
  getProfile,
  loginUser,
  logoutUser,
  userGetUsers,
  userAddUser,
  userUpdateUser,
  userDeleteUser,
  userVerifyDeliveryUser,
  userGetUnverifiedDeliveryUsers,
} from "../store/actions/userActions";
import { getAdminRole, getUserRole } from "src/utils/userRole";

let userService = {
  login: async (data, dispatch) => {
    try {
      let res = await http.post(
        "/api/v1/auth/signin?provider=credentials&platform=web",
        data
      );
      if (res.accessToken) {
        if (res.user.role === "VENDOR") {
          localStorage.setItem("restaurant", res.user?.restaurant);
        } else {
          localStorage.removeItem("restaurant");
        }
        localStorage.setItem("accessToken", res.accessToken);
        localStorage.setItem("userRole", String(res.user.role).toLowerCase());
      }
      dispatch(loginUser(res.user));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout: async (dispatch) => {
    try {
      let res = await http.get("/api/v1/logout");
      if (res && res.ok) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("restaurant");
        localStorage.removeItem("userRole");
        dispatch(logoutUser());
        return Promise.resolve(res);
      }
    } catch (error) {
      localStorage.removeItem("accessToken");
      dispatch(logoutUser());
    }
  },

  profile: async (dispatch) => {
    const user =
      localStorage.getItem("userRole")?.toLowerCase() === "editor"
        ? "editor"
        : "users";
    try {
      const res = await http.get(`/api/v1/${user}/profile`);
      dispatch(getProfile(res.user));
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getChatHistory: async (id, dispatch) => {
    const userRole = localStorage.getItem("userRole").toLowerCase();
    try {
      let res = await http.get(`/api/v1/${userRole}/list/${id}`);
      dispatch(
        setUserChatHisory({
          isReplace: true,
          data: res.data,
        })
      );
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export const userApiService = (
  roleRoute = localStorage.getItem("userRole")
) => {
  const userRoleRoute = getUserRole(roleRoute);
  const adminRoute = getAdminRole(roleRoute);

  return {
    // USER LOGIN
    login: async function (data, dispatch) {
      try {
        const res = await http.post("/api/v1/auth/admin/login", data);
        if (res.accessToken) {
          localStorage.setItem("accessToken", res.accessToken);
          localStorage.setItem("userRole", String(res.user.role).toLowerCase());
        }
        dispatch(loginUser(res.user));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    // USER LOGOUT
    logout: async function (dispatch) {
      try {
        const res = await http.get("/api/v1/logout");
        if (res && res.ok) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userRole");
          dispatch(logoutUser());
          return Promise.resolve(res);
        }
      } catch (error) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userRole");
        dispatch(logoutUser());
      }
    },

    // USER PROFILE
    profile: async function (dispatch) {
      const user = userRoleRoute === "editor" ? "editor" : "user";
      try {
        const res = await http.get(`/api/v1/${user}/profile`);
        dispatch(getProfile(res.data));
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    // GET USER LISTS
    getUsers: async function (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      role = "",
      status = ""
    ) {
      try {
        const res = await http.get(
          `/api/v1/admin-users?page=${currentPage}&search=${search}&role=${role}&status=${status}`
        );
        dispatch(userGetUsers(res.users));
        setPages && setPages(Math.ceil(res?.total / res?.limit) || 1);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getUser: async function (id) {
      try {
        const res = await http.get(`/api/v1/admin-users/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getRestaurant: async function () {
      try {
        const res = await http.get(`/api/v1/restaurants`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addUser: async function (data, dispatch) {
      try {
        const res = await http.post(`/api/v1/admin-users`, data);
        dispatch(userAddUser(res.user));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateUser: async function (id, data, dispatch) {
      try {
        const res = await http.patch(`/api/v1/admin-users/${id}`, data);
        dispatch(userUpdateUser(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteUser: async function (id, dispatch) {
      try {
        const res = await http.delete(`/api/v1/admin-users/${id}`);
        dispatch(userDeleteUser(id));
        window.location.reload();
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    // FOR DELIVERY USER VERIFICATION
    getUnverifiedDeliveryUsers: async function (dispatch) {
      try {
        const res = await http.get(`/api/v1/${adminRoute}/delivery/form`);
        dispatch(userGetUnverifiedDeliveryUsers(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    verifyDeliveryUser: async function (id, data, dispatch) {
      try {
        const res = await http.patch(
          `/api/v1/${adminRoute}/update/delivery/status/${id}`,
          data
        );
        if (res.ok) {
          dispatch(userVerifyDeliveryUser(res.data));
        }
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

export default userService;
