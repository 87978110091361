import {
    REMOVE_INFO_MESSAGE,
    SET_INFO_MESSAGE,
    USER_ADD_NOTIFICATION,
    USER_BROADCAST_NOTIFICATION,
    USER_ADD_PAGINATE_NOTIFICATION,
    USER_GET_NOFIFICATIONS,
    USER_NOFIFICATION_SEEN,
    NOTIFICATION_CLICKED,
    NOTIFICATION_RECEIVED
} from "../actions/notificationActions";

const initialState = {
    notificationClicked: localStorage.getItem('notification_seen') || 'false',
    notificationObj: {
        notifications: [],
        pageNo: 1,
        perPage: 15,
        totalPage: 1,
        unseenNotificationsLength: 1,
    },
    infoMessages: [],
};

export default function notificationReducer(state = initialState, { type, payload }) {
    switch (type) {
        // MANAGE NOTIFICATIONS...
        case USER_GET_NOFIFICATIONS: {
            const totalPageNumber = Number.isInteger(payload.total / 15) ? payload.total / 15 : Math.floor(payload.total / 15) + 1
            return { ...state, notificationObj: {...payload, unseenNotificationsLength: payload.unreadCount,totalPage: totalPageNumber,pageNo: payload.page} }
        }
        case USER_NOFIFICATION_SEEN: {
            const data = state.notificationObj.notifications;
            const index = data.findIndex((item) => item._id === payload._id);

            data.splice(index, 1, payload)
            const unseenLength = state.notificationObj.unseenNotificationsLength - 1;

            return { ...state, notificationObj: { ...state.notificationObj, notifications: data , unseenNotificationsLength: unseenLength,} }
        }
        case NOTIFICATION_CLICKED:{
            localStorage.setItem('notification_seen', true);
            return { ...state, notificationClicked: 'true' }
        }
        case NOTIFICATION_RECEIVED:{
            localStorage.setItem('notification_seen', false);
            return { ...state, notificationClicked: 'false' }
        }
        case USER_BROADCAST_NOTIFICATION:{
            const data = state.notificationObj.notifications;
            data.push(payload);
            const unseenLength = state.notificationObj.unseenNotificationsLength + 1;
            return { ...state, notificationObj: { ...state.notificationObj, notifications: data , unseenNotificationsLength: unseenLength} }
        }
        case USER_ADD_NOTIFICATION: {
            const data = state.notificationObj.notifications;
            data.unshift(payload);
          const unseenLength = state.notificationObj.unseenNotificationsLength + 1;
          return { ...state, notificationObj: { ...state.notificationObj, notifications: data , unseenNotificationsLength: unseenLength} }
        }
        case USER_ADD_PAGINATE_NOTIFICATION: {
            const data = state.notificationObj.notifications;
            payload.notifications.forEach((item) => {
                const isExist = data.some((val) => val === item);
                if (!isExist) { data.push(item) }
            })

            return { ...state, notificationObj: { ...state.notificationObj, notifications: data } }
        }


        // LOCAL INFO_NOTIFICATIONS...
        case SET_INFO_MESSAGE: {
            const data = [...state.infoMessages, payload];
            return { ...state, infoMessages: data };
        }
        case REMOVE_INFO_MESSAGE: {
            const data = state.infoMessages;
            const index = data.findIndex((item) => item === payload);
            data.splice(index, 1)
            return { ...state, infoMessages: data };
        }

        default:
            return state;
    }
}

// const getUnSeenNotificationLength = (notification_lists) => {
//   if (notification_lists && notification_lists.length > 1) {
//     const len = notification_lists.filter((val) => val.seen === false)
//     return Number(len.length);
//   }
//   return 0
// }

