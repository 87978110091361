// import qs from "qs";
import {
  userGetNotifications,
  userNotificationSeen,
  notificationClicked,
  notificationReceived,
} from "src/store/actions/notificationActions";
import { getAdminRole, getUserRole } from "src/utils/userRole";
import {
  commonGetSliders,
  commonAddSlider,
  commonUpdateSlider,
  commonDeleteSlider,

  // category reducer actions...
  commonGetCategories,
  commonAddCategory,
  commonUpdateCategory,
  commonDeleteCategory,

  // category reducer actions...
  commonGetSubCategories,
  commonAddSubCategory,
  commonUpdateSubCategory,
  commonDeleteSubCategory,

  // brand reducer actions...
  commonGetBrands,
  commonAddBrand,
  commonUpdateBrand,
  commonDeleteBrand,

  // product reducer actions...
  commonGetProducts,
  commonAddProduct,
  commonUpdateProduct,
  commonDeleteProduct,

  // featured reducer actions...
  commonGetFeatureds,
  commonAddFeatured,
  commonUpdateFeatured,
  commonDeleteFeatured,

  // promotion reducer actions...
  commonGetPromotions,
  commonAddPromotion,
  commonUpdatePromotion,
  commonDeletePromotion,

  // order reducer actions...
  commonGetOrders,
  commonUpdateOrder,
  commonDeleteOrder,

  //inventory reducer actions...
  commonGetInventories,
  commonUpdateInventory,
  // commonGetInventoryItem,

  //blog reducer actions...
  commonGetBlogs,
  commonAddBlog,
  commonUpdateBlog,
  commonDeleteBlog,

  //blog reducer actions...
  commonGetContactForms,
  commonDeleteContactForm,
  commonGetDeliverySchedules,
  commonAddDeliverySchedule,
  commonUpdateDeliverySchedule,
  commonDeleteDeliverySchedule,
  commonGetTimeSlots,
  commonAddTimeSlot,
  commonUpdateTimeSlot,
  commonDeleteTimeSlot,
} from "../store/actions/commonActions";
import http from "./api";

// ---------- exporting routes api functions ----------------------------------

// sliders api functions...
export const sliderApiService = () => {
  // const userRoleRoute = getAdminRole(roleRoute);

  return {
    getSliders: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      restaurant
    ) => {
      try {
        const uri = restaurant
          ? `/api/v1/banners?page=${
              currentPage || 1
            }&search=${search}&restaurant=${restaurant}`
          : `/api/v1/banners?page=${currentPage || 1}&search=${search}`;
        const res = await http.get(uri);
        dispatch(commonGetSliders(res.banners));
        setPages && setPages(Math.ceil(res?.total / res.limit));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getSlider: async (id) => {
      try {
        const res = await http.get(`/api/v1/banners/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addSlider: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/banners`, data);
        dispatch(commonAddSlider(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateSlider: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/banners/${id}`, data);
        dispatch(commonUpdateSlider(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteSlider: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/banners/${id}`);
        dispatch(commonDeleteSlider(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// category api functions...
export const categoryApiService = () => {
  // const adminRoute = getAdminRole(roleRoute);

  return {
    getCategories: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      businessType,
      restaurant
    ) => {
      try {
        const params = {};
        params["page"] = currentPage;
        if (search) params["search"] = search;
        if (restaurant) params["restaurant"] = restaurant;
        if (businessType) params["businessTypes"] = businessType.toString();
        const res = await http({ url: "/api/v1/categories", params: params });
        dispatch(commonGetCategories(res?.categories));
        setPages && setPages(Math.ceil(res?.total / res.limit));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getCategory: async (id) => {
      try {
        const res = await http.get(`/api/v1/categories/${id}`);
        return Promise.resolve(res?.category);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addCategory: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/categories`, data);
        dispatch(commonAddCategory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    selectAndCreateCategory: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/categories/link_categories`, data);
        dispatch(commonAddCategory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateCategory: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/categories/${id}`, data);
        dispatch(commonUpdateCategory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteCategory: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/categories/${id}`);
        dispatch(commonDeleteCategory(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// sub-category api functions...
export const subCategoryApiService = () => {
  // const userRoleRoute = roleRoute.toLowerCase();

  return {
    getSubCategories: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      businessType,
      restaurant
    ) => {
      try {
        const params = {};
        params["page"] = currentPage;
        if (search) params["search"] = search;
        if (restaurant) params["restaurant"] = restaurant;
        if (businessType) params["businessTypes"] = businessType.toString();
        console.log(params);
        const res = await http({
          url: "/api/v1/sub-categories",
          params: params,
        });
        dispatch(commonGetSubCategories(res?.subCategories));
        setPages && setPages(Math.ceil(res?.total / res.limit));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getSubCategory: async (id) => {
      try {
        const res = await http.get(`/api/v1/sub-categories/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addSubCategory: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/sub-categories`, data);
        dispatch(commonAddSubCategory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    selectAndCreateSubCategory: async (data, dispatch) => {
      try {
        const res = await http.post(
          `/api/v1/sub-categories/link_subcategory`,
          data
        );
        dispatch(commonAddCategory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateSubCategory: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/sub-categories/${id}`, data);
        dispatch(commonUpdateSubCategory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteSubCategory: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/sub-categories/${id}`);
        dispatch(commonDeleteSubCategory(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// brand api functions...
export const brandApiService = () => {
  return {
    getBrands: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      restaurant
    ) => {
      try {
        const uri = restaurant
          ? `/api/v1/brands?page=${currentPage}&search=${search}&restaurant=${restaurant}`
          : `/api/v1/brands?page=${currentPage}&search=${search}`;
        const res = await http.get(uri);
        dispatch(commonGetBrands(res?.brands));
        setPages && setPages(Math.ceil(res?.total / res?.limit));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getBrand: async (id) => {
      try {
        const res = await http.get(`/api/v1/brands/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addBrand: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/brands`, data);
        dispatch(commonAddBrand(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    selectAndCreateBrand: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/brands/link_brands`, data);
        dispatch(commonAddBrand(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateBrand: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/brands/${id}`, data);
        dispatch(commonUpdateBrand(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteBrand: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/brands/${id}`);
        dispatch(commonDeleteBrand(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// product api functions...
export const productApiService = () => {
  return {
    getProducts: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      restaurant
    ) => {
      try {
        const uri = restaurant
          ? `/api/v1/products?page=${currentPage}&search=${search}&restaurant=${restaurant}`
          : `/api/v1/products?page=${currentPage}&search=${search}`;
        const res = await http.get(uri);
        dispatch(commonGetProducts(res?.products));
        setPages && setPages(Math.ceil(res?.total / res?.limit));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getProduct: async (id) => {
      try {
        const res = await http.get(`/api/v1/products/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addProduct: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/products`, data);
        dispatch(commonAddProduct(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateProduct: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/products/${id}`, data);
        dispatch(commonUpdateProduct(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteProduct: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/products/${id}`);
        dispatch(commonDeleteProduct(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// featured api functions...
export const featuredApiService = (
  roleRoute = localStorage.getItem("userRole")
) => {
  const userRoleRoute = roleRoute.toLowerCase();

  return {
    getFeatureds: async (dispatch) => {
      try {
        const res = await http.get(`/api/v1/${userRoleRoute}/featured`);
        dispatch(commonGetFeatureds(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getFeatured: async (id) => {
      try {
        const res = await http.get(`/api/v1/${userRoleRoute}/featured/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addFeatured: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/${userRoleRoute}/featured`, data);
        res.data.businessType = res.data.businessType._id;
        dispatch(commonAddFeatured(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateFeatured: async (id, data, dispatch) => {
      try {
        const res = await http.patch(
          `/api/v1/${userRoleRoute}/featured/${id}`,
          data
        );
        dispatch(commonUpdateFeatured(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteFeatured: async (id, dispatch) => {
      try {
        const res = await http.delete(
          `/api/v1/${userRoleRoute}/featured/${id}`
        );
        dispatch(commonDeleteFeatured(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// promotions api functions...
export const promotionsApiService = () => {
  return {
    getPromotions: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      restaurant
    ) => {
      try {
        const uri = restaurant
          ? `/api/v1/promotion?page=${currentPage}&search=${search}&restaurant=${restaurant}`
          : `/api/v1/promotion?page=${currentPage}&search=${search}`;
        const res = await http.get(uri);
        dispatch(commonGetPromotions(res?.promotions));
        setPages && setPages(Math.ceil(res?.total / res?.limit));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getPromotion: async (id) => {
      try {
        const res = await http.get(`/api/v1/promotion/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addPromotion: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/promotion`, data);
        dispatch(commonAddPromotion(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updatePromotion: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/promotion/${id}`, data);
        dispatch(commonUpdatePromotion(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deletePromotion: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/promotion/${id}`);
        dispatch(commonDeletePromotion(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// order api functions...
export const orderApiService = () => {
  return {
    getOrders: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      restaurant
    ) => {
      try {
        const uri = restaurant
          ? `/api/v1/orders?page=${currentPage}&serialId=${search}&restaurant=${restaurant}`
          : `/api/v1/orders?page=${currentPage}&serialId=${search}`;
        const res = await http.get(uri);
        dispatch(commonGetOrders(res.orders));
        setPages && setPages(Math.ceil(res?.total / (res?.limit || 10)) || 1);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getOrder: async (id) => {
      try {
        const res = await http.get(`/api/v1/orders/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateOrder: async (id, data, dispatch, key) => {
      try {
        const res = await http.patch(
          `/api/v1/orders/${id}`,
          key === "item" ? { orderItems: data } : data
        );
        dispatch(commonUpdateOrder(res?.order));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateOrderItems: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/orders${id}/items`, data);
        dispatch(commonUpdateOrder(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteOrder: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/orders/${id}`);
        dispatch(commonDeleteOrder(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// inventory api functions...
export const inventoryApiService = (
  roleRoute = localStorage.getItem("userRole")
) => {
  const userRoleRoute = roleRoute.toLowerCase();

  return {
    getInventories: async (dispatch) => {
      try {
        const res = await http.get(`/api/v1/${userRoleRoute}/inventory`);
        dispatch(commonGetInventories(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getInventory: async (id) => {
      try {
        const res = await http.get(`/api/v1/${userRoleRoute}/inventory/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateInventory: async (id, data, dispatch) => {
      try {
        const res = await http.patch(
          `/api/v1/${userRoleRoute}/inventory/${id}`,
          data
        );
        dispatch(commonUpdateInventory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateStock: async (data, dispatch) => {
      try {
        const res = await http.post(
          `/api/v1/${userRoleRoute}/inventory/update/stock`,
          data
        );
        dispatch(commonUpdateInventory(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    addStock: async (data) => {
      try {
        const res = await http.post(
          `/api/v1/${userRoleRoute}/inventory/add-stock`,
          data
        );
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    addExpired: async (data) => {
      try {
        const res = await http.post(
          `/api/v1/${userRoleRoute}/inventory/add-expired`,
          data
        );
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    addDamaged: async (data) => {
      try {
        const res = await http.post(
          `/api/v1/${userRoleRoute}/inventory/add-damaged`,
          data
        );
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// blog api functions...
export const blogApiService = () => {
  return {
    getBlogs: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = "",
      restaurant
    ) => {
      try {
        const uri = restaurant
          ? `/api/v1/blogs?page=${currentPage}&search=${search}&restaurant=${restaurant}`
          : `/api/v1/blogs?page=${currentPage}&search=${search}`;
        const res = await http.get(uri);
        setPages && setPages(Math.ceil(res?.total / (res?.limit || 10)) || 1);
        dispatch(commonGetBlogs(res?.blogs));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getBlog: async (id) => {
      try {
        const res = await http.get(`/api/v1/blogs/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addBlog: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/blogs`, data);
        dispatch(commonAddBlog(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateBlog: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/blogs/${id}`, data);
        dispatch(commonUpdateBlog(res.blog));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteBlog: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/blogs/${id}`);

        dispatch(commonDeleteBlog(id));

        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// contact api functions...
export const contactApiService = () => {
  return {
    getContactForms: async (
      dispatch,
      currentPage = 1,
      setPages,
      search = ""
    ) => {
      try {
        const res = await http.get(
          `/api/v1/contact?page=${currentPage}&search=${search}`
        );
        dispatch(commonGetContactForms(res.data));
        setPages && setPages(Math.ceil(res?.total / (res?.limit || 10)) || 1);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getContactForm: async (id) => {
      try {
        const res = await http.get(`/api/v1/contact/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteContactForm: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/contact/${id}`);
        dispatch(commonDeleteContactForm(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// delivery schedule api functions...
export const deliveryScheduleApiService = () => {
  // let userRoleRoute = roleRoute.toLowerCase();
  // if (roleRoute === "admin" || roleRoute === "superadmin") {
  //     userRoleRoute = "admin"
  // }

  return {
    getDeliverySchedules: async (dispatch) => {
      try {
        const res = await http.get(`/api/v1/time-slots`);
        dispatch(commonGetDeliverySchedules(res.timeSlot));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getDeliverySchedule: async (id) => {
      try {
        const res = await http.get(`/api/v1/time-slots/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addDeliverySchedule: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/time-slots`, data);
        dispatch(commonAddDeliverySchedule(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateDeliverySchedule: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/time-slots/${id}`, data);
        dispatch(commonUpdateDeliverySchedule(res.data));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteDeliverySchedule: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/time-slots/${id}`);
        dispatch(commonDeleteDeliverySchedule(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// time slot api functions...
export const timeSlotApiService = (roleRoute = getUserRole()) => {
  const adminRoute = getAdminRole(roleRoute);

  return {
    getTimeSlots: async (dispatch) => {
      try {
        const res = await http.get(`/api/v1/time-slots/time/`);
        dispatch(commonGetTimeSlots(res.times));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getTimeSlot: async (id) => {
      try {
        const res = await http.get(`/api/v1/${adminRoute}/time/${id}`);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    addTimeSlot: async (data, dispatch) => {
      try {
        const res = await http.post(`/api/v1/time-slots/time`, data);
        dispatch(commonAddTimeSlot(res.time));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateTimeSlot: async (id, data, dispatch) => {
      try {
        const res = await http.patch(`/api/v1/time-slots/time/${id}`, data);
        dispatch(commonUpdateTimeSlot(res.time));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteTimeSlot: async (id, dispatch) => {
      try {
        const res = await http.delete(`/api/v1/time-slots/time/${id}`);
        dispatch(commonDeleteTimeSlot(id));
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

// NOTIFICATIONS API FUNCTIONS...
export const notificationApiService = () => {
  return {
    getNotifications: async function (dispatch, page = 1) {
      try {
        const res = await http.get(
          `/api/v1/notifications?page=${page || 1}&limit=15`
        );
        if (res.ok) {
          dispatch(userGetNotifications(res));
        }
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getNotificationsPaginate: async function (page, size, dispatch) {
      try {
        const res = await http.get(
          `/api/v1/notifications?page=${page || 1}&&size=${size || 15}`
        );
        if (res.ok) {
          dispatch(userGetNotifications(res.data));
        }
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    notificationSeen: async function (item, dispatch) {
      try {
        const res = await http.patch(`/api/v1/notifications/${item._id}`, {
          seen: "true",
        });
        if (res.ok) {
          dispatch(userNotificationSeen(res.data));
        }
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    notificationClicked: async function (dispatch) {
      dispatch(notificationClicked());
    },
    notificationReceived: async function (dispatch) {
      dispatch(notificationReceived());
    },
  };
};
