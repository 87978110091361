import {
  COMMON_ADD_BLOG,
  COMMON_ADD_BRAND,
  COMMON_ADD_CATEGORY,
  COMMON_ADD_DELIVERY_SCHEDULE,
  COMMON_ADD_FEATURED,
  COMMON_ADD_PRODUCT,
  COMMON_ADD_PROMOTION,
  COMMON_ADD_SLIDER,
  COMMON_ADD_SUBCATEGORY,
  COMMON_ADD_TIMESLOT,
  COMMON_DELETE_BLOG,
  COMMON_DELETE_BRAND,
  COMMON_DELETE_CATEGORY,
  COMMON_DELETE_CONTACT_FORM,
  COMMON_DELETE_DELIVERY_SCHEDULE,
  COMMON_DELETE_FEATURED,
  COMMON_DELETE_ORDER,
  COMMON_DELETE_PRODUCT,
  COMMON_DELETE_PROMOTION,
  COMMON_DELETE_SLIDER,
  COMMON_DELETE_SUBCATEGORY,
  COMMON_DELETE_TIMESLOT,
  COMMON_GET_BLOGS,
  COMMON_GET_BRANDS,
  COMMON_GET_CATEGORIES,
  COMMON_GET_CONTACT_FORMS,
  COMMON_GET_DELIVERY_SCHEDULES,
  COMMON_GET_FEATUREDS,
  COMMON_GET_INVENTORIES,
  COMMON_GET_ORDERS,
  COMMON_GET_PRODUCTS,
  COMMON_GET_PROMOTIONS,
  COMMON_GET_SLIDERS,
  COMMON_GET_SUB_CATEGORIES,
  COMMON_GET_TIMESLOTS,
  COMMON_UPDATE_BLOG,
  COMMON_UPDATE_BRAND,
  COMMON_UPDATE_CATEGORY,
  COMMON_UPDATE_DELIVERY_SCHEDULE,
  COMMON_UPDATE_FEATURED,
  COMMON_UPDATE_INVENTORY,
  COMMON_UPDATE_ORDER,
  COMMON_UPDATE_ORDERS,
  COMMON_UPDATE_PRODUCT,
  COMMON_UPDATE_PROMOTION,
  COMMON_UPDATE_SLIDER,
  COMMON_UPDATE_SUBCATEGORY,
  COMMON_UPDATE_TIMESLOT,
  COMMON_SET_CURRENT_PAGE,
  COMMON_SET_PAGES,
} from "../actions/commonActions";

const initialState = {
  sliders: [],
  categories: [],
  subcategories: [],
  brands: [],
  products: [],
  featureds: [],
  promotions: [],
  orders: [],
  inventory: [],
  blogs: [],
  contactForm: [],
  deliverySchedule: [],
  timeSlot: [],
  notifications: [],
  pages: 1,
  currentPage: 1,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    // SLIDERS
    case COMMON_GET_SLIDERS:
      return {
        ...state,
        sliders: action.payload,
      };
    case COMMON_ADD_SLIDER: {
      let sliders = [...state.sliders];
      sliders.unshift(action.payload);
      return {
        ...state,
        sliders: sliders,
      };
    }
    case COMMON_UPDATE_SLIDER: {
      let sliders = [...state.sliders];
      let index = sliders.findIndex((cat) => cat._id === action.payload._id);
      sliders[index] = action.payload;
      return {
        ...state,
        sliders: sliders,
      };
    }
    case COMMON_DELETE_SLIDER: {
      let sliders = [...state.sliders];
      let index = sliders.findIndex((cat) => cat._id === action.payload);
      sliders.splice(index, 1);
      return {
        ...state,
        sliders: sliders,
      };
    }

    // CATEGORIES...
    case COMMON_GET_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
      };
    case COMMON_ADD_CATEGORY: {
      const categories = [...state.categories];
      categories.unshift(action.payload);
      return {
        ...state,
        categories: categories,
      };
    }
    case COMMON_UPDATE_CATEGORY: {
      let categories = [...state.categories];
      let index = categories.findIndex((cat) => cat._id === action.payload._id);
      categories[index] = action.payload;
      return {
        ...state,
        categories: categories,
      };
    }
    case COMMON_DELETE_CATEGORY: {
      let categories = [...state.categories];
      let index = categories.findIndex((cat) => cat._id === action.payload);
      categories.splice(index, 1);
      return {
        ...state,
        categories: categories,
      };
    }

    // SUB CATEGORY
    case COMMON_GET_SUB_CATEGORIES:
      return {
        ...state,
        subcategories: action.payload,
      };
    case COMMON_ADD_SUBCATEGORY: {
      const categories = [...state.categories];
      const subcategories = [...state.subcategories];
      if (subcategories.length > 0) subcategories.unshift(action.payload);
      const cat_id =
        action.payload &&
        action.payload.category &&
        action.payload.category._id;
      if (cat_id) {
        const index = categories.findIndex((cat) => cat._id === cat_id);
        const category = categories[index];
        if (category) {
          if (category.subcategory && Array.isArray(category.subcategory)) {
            category.subcategory.unshift(action.payload);
            categories[index] = category;
          }
        }
      }
      return {
        ...state,
        categories: categories,
        subcategories: subcategories,
      };
    }
    case COMMON_UPDATE_SUBCATEGORY: {
      let subcategories = [...state.subcategories];
      let index = subcategories.findIndex(
        (cat) => cat._id === action.payload._id
      );
      subcategories[index] = action.payload;

      const categories = [...state.categories];
      const cat_id =
        action.payload &&
        action.payload.category &&
        action.payload.category._id;
      if (cat_id) {
        const index = categories.findIndex((cat) => cat._id === cat_id);
        const category = categories[index];
        if (category) {
          if (category.subcategory && Array.isArray(category.subcategory)) {
            const sub_index = category.subcategory.findIndex(
              (item) => item._id === action.payload._id
            );
            category.subcategory[sub_index] = action.payload;
            categories[index] = category;
          }
        }
      }
      return {
        ...state,
        categories: categories,
        subcategories: subcategories,
      };
    }
    case COMMON_DELETE_SUBCATEGORY: {
      let subcategories = [...state.subcategories];
      let index = subcategories.findIndex(
        (cat) => cat._id === action.payload._id
      );
      subcategories.splice(index, 1);

      const categories = [...state.categories];
      const cat_id = action.payload && action.payload.category;
      if (cat_id) {
        const index = categories.findIndex((cat) => cat._id === cat_id);
        const category = categories[index];
        if (category) {
          if (category.subcategory && Array.isArray(category.subcategory)) {
            const sub_index = category.subcategory.findIndex(
              (item) => item._id === action.payload._id
            );
            category.subcategory.splice(sub_index, 1);
            categories[index] = category;
          }
        }
      }
      return {
        ...state,
        // categories: categories,
        subcategories: subcategories,
      };
    }

    // BRANDS
    case COMMON_GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case COMMON_ADD_BRAND: {
      let brands = [...state.brands];
      brands.unshift(action.payload);
      return {
        ...state,
        brands: brands,
      };
    }
    case COMMON_UPDATE_BRAND: {
      let brands = [...state.brands];
      let index = brands.findIndex((cat) => cat._id === action.payload._id);
      brands[index] = action.payload;
      return {
        ...state,
        brands: brands,
      };
    }
    case COMMON_DELETE_BRAND: {
      let brands = [...state.brands];
      let index = brands.findIndex((cat) => cat._id === action.payload);
      brands.splice(index, 1);
      return {
        ...state,
        brands: brands,
      };
    }

    // PRODUCTS
    case COMMON_GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case COMMON_ADD_PRODUCT: {
      let products = [...state.products];
      products.unshift(action.payload);
      return {
        ...state,
        products: products,
      };
    }
    case COMMON_UPDATE_PRODUCT: {
      let products = [...state.products];
      let index = products.findIndex((cat) => cat._id === action.payload._id);
      products[index] = action.payload;
      return {
        ...state,
        products: products,
      };
    }
    case COMMON_DELETE_PRODUCT: {
      let products = [...state.products];
      let index = products.findIndex((cat) => cat._id === action.payload);
      products.splice(index, 1);
      return {
        ...state,
        products: products,
      };
    }

    // FEATUREDS
    case COMMON_GET_FEATUREDS:
      return {
        ...state,
        featureds: action.payload,
      };
    case COMMON_ADD_FEATURED: {
      let featureds = [...state.featureds];
      featureds.unshift(action.payload);
      return {
        ...state,
        featureds: featureds,
      };
    }
    case COMMON_UPDATE_FEATURED: {
      let featureds = [...state.featureds];
      let index = featureds.findIndex((cat) => cat._id === action.payload._id);
      featureds[index] = action.payload;
      return {
        ...state,
        featureds: featureds,
      };
    }
    case COMMON_DELETE_FEATURED: {
      let featureds = [...state.featureds];
      let index = featureds.findIndex((cat) => cat._id === action.payload);
      featureds.splice(index, 1);
      return {
        ...state,
        featureds: featureds,
      };
    }

    // PROMOTIONS
    case COMMON_GET_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload,
      };
    case COMMON_ADD_PROMOTION: {
      let promotions = [...state.promotions];
      promotions.unshift(action.payload);
      return {
        ...state,
        promotions: promotions,
      };
    }
    case COMMON_UPDATE_PROMOTION: {
      let promotions = [...state.promotions];
      let index = promotions.findIndex((cat) => cat._id === action.payload._id);
      promotions[index] = action.payload;
      return {
        ...state,
        promotions: promotions,
      };
    }
    case COMMON_DELETE_PROMOTION: {
      let promotions = [...state.promotions];
      let index = promotions.findIndex((cat) => cat._id === action.payload);
      promotions.splice(index, 1);
      return {
        ...state,
        promotions: promotions,
      };
    }

    // ORDERS
    case COMMON_GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case COMMON_UPDATE_ORDERS: {
      const updatedOrders = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      return {
        ...state,
        orders: [...updatedOrders, ...state.orders],
      };
    }
    case COMMON_UPDATE_ORDER: {
      let orders = [...state.orders];
      let index = orders.findIndex((cat) => cat._id === action.payload._id);
      orders[index] = action.payload;
      return {
        ...state,
        orders: orders,
      };
    }
    case COMMON_DELETE_ORDER: {
      let orders = [...state.orders];
      let index = orders.findIndex((cat) => cat._id === action.payload);
      orders.splice(index, 1);
      return {
        ...state,
        orders: orders,
      };
    }

    // INVENTORY
    case COMMON_GET_INVENTORIES: {
      return {
        ...state,
        inventory: action.payload,
      };
    }
    case COMMON_UPDATE_INVENTORY: {
      let inventory = [...state.inventory];
      let index = inventory.findIndex((cat) => cat._id === action.payload._id);
      inventory[index] = action.payload;
      return {
        ...state,
        inventory: inventory,
      };
    }

    // BLOGS
    case COMMON_GET_BLOGS: {
      return {
        ...state,
        blogs: action.payload,
      };
    }
    case COMMON_ADD_BLOG: {
      let blogs = [...state.blogs];
      blogs.unshift(action.payload);
      return {
        ...state,
        blogs: blogs,
      };
    }
    case COMMON_UPDATE_BLOG: {
      let blogs = [...state.blogs];
      let index = blogs.findIndex((cat) => cat._id === action.payload._id);
      blogs[index] = action.payload;
      return {
        ...state,
        blogs: blogs,
      };
    }
    case COMMON_DELETE_BLOG: {
      let blogs = [...state.blogs];
      let index = blogs.findIndex((cat) => cat._id === action.payload);
      blogs.splice(index, 1);
      return {
        ...state,
        blogs: blogs,
      };
    }

    // CONTACT FORM
    case COMMON_GET_CONTACT_FORMS: {
      return {
        ...state,
        contactForm: action.payload,
      };
    }
    case COMMON_DELETE_CONTACT_FORM: {
      let contactForm = [...state.contactForm];
      let index = contactForm.findIndex((cat) => cat._id === action.payload);
      contactForm.splice(index, 1);
      return {
        ...state,
        contactForm: contactForm,
      };
    }

    // DELIVERY SCHEDULE
    case COMMON_GET_DELIVERY_SCHEDULES:
      return { ...state, deliverySchedule: action.payload };
    case COMMON_ADD_DELIVERY_SCHEDULE: {
      const dSchedule = [...state.deliverySchedule];
      dSchedule.push(action.payload);
      return {
        ...state,
        deliverySchedule: dSchedule,
      };
    }
    case COMMON_UPDATE_DELIVERY_SCHEDULE: {
      const dSchedule = [...state.deliverySchedule];
      const index = dSchedule.findIndex(
        (cat) => cat._id === action.payload._id
      );
      dSchedule[index] = action.payload;
      return {
        ...state,
        deliverySchedule: dSchedule,
      };
    }
    case COMMON_DELETE_DELIVERY_SCHEDULE: {
      const dSchedule = [...state.deliverySchedule];
      const index = dSchedule.findIndex(
        (cat) => cat._id === action.payload._id
      );
      dSchedule.splice(index, 1);
      return {
        ...state,
        deliverySchedule: dSchedule,
      };
    }

    // TIME SLOT SCHEDULE
    case COMMON_GET_TIMESLOTS:
      return { ...state, timeSlot: action.payload };
    case COMMON_ADD_TIMESLOT: {
      const timeslot = [...state.timeSlot];
      timeslot.push(action.payload);
      return {
        ...state,
        timeSlot: timeslot,
      };
    }
    case COMMON_UPDATE_TIMESLOT: {
      const timeslot = [...state.timeSlot];
      const index = timeslot.findIndex((cat) => cat._id === action.payload._id);
      timeslot[index] = action.payload;
      return {
        ...state,
        timeSlot: timeslot,
      };
    }
    case COMMON_DELETE_TIMESLOT: {
      const timeslot = [...state.timeSlot];
      const index = timeslot.findIndex((cat) => cat._id === action.payload);
      timeslot.splice(index, 1);
      return {
        ...state,
        timeSlot: timeslot,
      };
    }

    //paginations
    case COMMON_SET_PAGES:
      return {
        ...state,
        pages: action.payload,
      };
    case COMMON_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    // DEFAULT
    default:
      return state;
  }
}
