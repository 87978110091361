// import { consoleLog } from "src/utils/helper";
import {
  ADMIN_GET_PROFIT_PRODUCTS,
  ADMIN_GET_PROFIT_ORDERS,
  ADMIN_GET_PROFIT_USERS,
  ADMIN_GET_TRANSACTIONS,
  ADMIN_GET_ERROR_LOGS,
  ADMIN_DELETE_ERROR_LOG,
  ADMIN_GET_RESTAURANTS,
  ADMIN_POST_RESTAURANT,
  ADMIN_UPDATE_RESTAURANT,
  ADMIN_DELETE_RESTAURANT,
  ADMIN_GET_SERVICES,
  ADMIN_CREATE_SERVICES,
  ADMIN_UPDATE_SERVICES,
  ADMIN_DELETE_SERVICES,
  ADMIN_GET_ABOUT_US,
  ADMIN_POST_ABOUT_US,
} from "../actions/adminActions";

const initialState = {
  transactions: [],
  errorlogs: [],
  restaurants: [],
  services: [],
  about: [],
  profit: {
    products: [],
    orders: [],
    users: [],
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    //ABOUT
    case ADMIN_GET_ABOUT_US: {
      return {
        ...state,
        about: action.payload,
      };
    }
    case ADMIN_POST_ABOUT_US: {
      return {
        ...state,
        about: action.payload,
      };
    }
    // RESTAURANTS
    case ADMIN_GET_RESTAURANTS: {
      return {
        ...state,
        restaurants: action.payload,
      };
    }

    case ADMIN_POST_RESTAURANT: {
      let list = [...state.restaurants];
      list.unshift(action.payload);
      return {
        ...state,
        restaurants: list,
      };
    }

    case ADMIN_UPDATE_RESTAURANT: {
      let list = [...state.restaurants];
      list.unshift(action.payload);
      return {
        ...state,
        restaurants: list,
      };
    }

    case ADMIN_DELETE_RESTAURANT: {
      let list = [...state.restaurants];
      let index = list.findIndex((cat) => cat._id === action.payload);
      list.splice(index, 1);
      return {
        ...state,
        restaurants: list,
      };
    }

    //SERVICES
    case ADMIN_GET_SERVICES: {
      return {
        ...state,
        services: action.payload,
      };
    }

    case ADMIN_CREATE_SERVICES: {
      let list = [...state.services];
      list.unshift(action.payload);
      return {
        ...state,
        services: list,
      };
    }

    case ADMIN_UPDATE_SERVICES: {
      let list = [...state.services];
      list.unshift(action.payload);
      return {
        ...state,
        services: list,
      };
    }

    case ADMIN_DELETE_SERVICES: {
      let list = [...state.services];
      let index = list.findIndex((cat) => cat._id === action.payload);
      list.splice(index, 1);
      return {
        ...state,
        services: list,
      };
    }

    case ADMIN_GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };

    // ERROR LOGS
    case ADMIN_GET_ERROR_LOGS:
      return {
        ...state,
        errorlogs: action.payload,
      };
    case ADMIN_DELETE_ERROR_LOG: {
      let errorlogs = [...state.errorlogs];
      let index = errorlogs.findIndex((log) => log._id === action.payload);
      errorlogs.splice(index, 1);
      return {
        ...state,
        errorlogs: errorlogs,
      };
    }

    // PROFIT
    case ADMIN_GET_PROFIT_PRODUCTS: {
      return {
        ...state,
        profit: {
          ...state.profit,
          products: action.payload,
        },
      };
    }
    case ADMIN_GET_PROFIT_ORDERS: {
      return {
        ...state,
        profit: {
          ...state.profit,
          orders: action.payload,
        },
      };
    }
    case ADMIN_GET_PROFIT_USERS: {
      return {
        ...state,
        profit: {
          ...state.profit,
          users: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
