import {
  SUPERADMIN_GET_PROFILE,
  SUPERADMIN_GET_BRANCHES,
  SUPERADMIN_ADD_BRANCH,
  SUPERADMIN_UPDATE_BRANCH,
  SUPERADMIN_DELETE_BRANCH,
  SUPERADMIN_GET_BUSINESSTYPES,
  SUPERADMIN_ADD_BUSINESSTYPE,
  SUPERADMIN_UPDATE_BUSINESSTYPE,
  SUPERADMIN_DELETE_BUSINESSTYPE,
  SUPERADMIN_GET_REVIEWS,
  SUPERADMIN_ADD_REVIEW,
  SUPERADMIN_UPDATE_REVIEW,
  SUPERADMIN_DELETE_REVIEW,
  SUPERADMIN_GET_TESTIMONIALS,
  SUPERADMIN_ADD_TESTIMONIAL,
  SUPERADMIN_UPDATE_TESTIMONIAL,
  SUPERADMIN_DELETE_TESTIMONIAL,
  SUPERADMIN_GET_OFFERS,
  SUPERADMIN_ADD_OFFER,
  SUPERADMIN_UPDATE_OFFER,
  SUPERADMIN_DELETE_OFFER,
} from "../actions/superAdminActions";

const initialState = {
  admin: [],
  businessTypes: [],
  branches: [],
  reviews: [],
  testimonials: [],
  festivalOffers: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    // BUSINES TYPE
    case SUPERADMIN_GET_BUSINESSTYPES:
      return { ...state, businessTypes: action.payload };
    case SUPERADMIN_ADD_BUSINESSTYPE: {
      let btypes = [...state.businessTypes];
      btypes.unshift(action.payload);
      return { ...state, businessTypes: btypes };
    }
    case SUPERADMIN_GET_PROFILE:
      return { ...state, admin: action.payload };
    case SUPERADMIN_UPDATE_BUSINESSTYPE: {
      let btypes = [...state.businessTypes];
      const index = btypes.findIndex((item) => item._id === action.payload._id);
      btypes[index] = action.payload;
      return { ...state, businessTypes: btypes };
    }
    case SUPERADMIN_DELETE_BUSINESSTYPE: {
      let btypes = [...state.businessTypes];
      const index = btypes.findIndex((item) => item._id === action.payload);
      btypes.splice(index, 1);
      return { ...state, businessTypes: btypes };
    }

    // BRANCHES
    case SUPERADMIN_GET_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case SUPERADMIN_ADD_BRANCH: {
      let branches = [...state.branches];
      branches.unshift(action.payload);
      return {
        ...state,
        branches: branches,
      };
    }
    case SUPERADMIN_UPDATE_BRANCH: {
      let branches = [...state.branches];
      let index = branches.findIndex((cat) => cat._id === action.payload._id);
      branches[index] = action.payload;
      return {
        ...state,
        branches: branches,
      };
    }
    case SUPERADMIN_DELETE_BRANCH: {
      let branches = [...state.branches];
      let index = branches.findIndex((cat) => cat._id === action.payload);
      branches.splice(index, 1);
      return {
        ...state,
        branches: branches,
      };
    }

    //TESTIMONIALS
    case SUPERADMIN_GET_TESTIMONIALS: {
      return {
        ...state,
        testimonials: action.payload,
      };
    }
    case SUPERADMIN_ADD_TESTIMONIAL: {
      let testimonials = [...state.testimonials];
      testimonials.unshift(action.payload);
      return {
        ...state,
        testimonials: testimonials,
      };
    }
    case SUPERADMIN_UPDATE_TESTIMONIAL: {
      let testimonials = [...state.testimonials];
      let index = testimonials.findIndex(
        (data) => data._id === action.payload._id
      );
      testimonials[index] = action.payload;
      return {
        ...state,
        testimonials: testimonials,
      };
    }
    case SUPERADMIN_DELETE_TESTIMONIAL: {
      let testimonials = [...state.testimonials];
      let index = testimonials.findIndex((data) => data._id === action.payload);
      testimonials.splice(index, 1);
      return {
        ...state,
        testimonials: testimonials,
      };
    }
    //Festival Offers
    case SUPERADMIN_GET_OFFERS: {
      return {
        ...state,
        festivalOffers: action.payload,
      };
    }
    case SUPERADMIN_ADD_OFFER: {
      let festivalOffers = [...state.festivalOffers];
      festivalOffers.unshift(action.payload);
      return {
        ...state,
        festivalOffers: festivalOffers,
      };
    }
    case SUPERADMIN_UPDATE_OFFER: {
      let festivalOffers = [...state.festivalOffers];
      let index = festivalOffers.findIndex(
        (data) => data._id === action.payload._id
      );
      festivalOffers[index] = action.payload;
      return {
        ...state,
        festivalOffers: festivalOffers,
      };
    }
    case SUPERADMIN_DELETE_OFFER: {
      let festivalOffers = [...state.festivalOffers];
      let index = festivalOffers.findIndex(
        (data) => data._id === action.payload
      );
      festivalOffers.splice(index, 1);
      return {
        ...state,
        festivalOffers: festivalOffers,
      };
    }

    // REVIEWS
    case SUPERADMIN_GET_REVIEWS: {
      return {
        ...state,
        reviews: action.payload,
      };
    }
    case SUPERADMIN_ADD_REVIEW: {
      let reviews = [...state.reviews];
      reviews.unshift(action.payload);
      return {
        ...state,
        reviews: reviews,
      };
    }
    case SUPERADMIN_UPDATE_REVIEW: {
      let reviews = [...state.reviews];
      let index = reviews.findIndex((data) => data._id === action.payload._id);
      reviews[index] = action.payload;
      return {
        ...state,
        reviews: reviews,
      };
    }
    case SUPERADMIN_DELETE_REVIEW: {
      let reviews = [...state.reviews];
      let index = reviews.findIndex((data) => data._id === action.payload);
      reviews.splice(index, 1);
      return {
        ...state,
        reviews: reviews,
      };
    }

    default:
      return state;
  }
}
